

















































import { Component, Vue } from "vue-property-decorator";
import {
    apiNoticeAdd,
    apiNoticeDetail,
    apiNoticeEdit,
} from "@/api/marketing/notice";
import MaterialSelect from '@/components/material-select/index.vue'
import LsEditor from "@/components/editor.vue";
import { PageMode } from "@/utils/type";

@Component({
    components: {
        LsEditor,
        MaterialSelect
    },
})
export default class HelpEdit extends Vue {
    /** S Data **/
    mode: string = PageMode["ADD"]; // 当前页面: add-添加角色 edit-编辑角色

    // 分类列表
    categoryList: Array<object> = [];

    // 表单数据
    form:any = {
        id: 0, // 当前编辑用户的身份ID
        name: "", // 标题
        synopsis: "", // 简介
        image: "", // 封面图片
        content: "", // 内容
        sort: 0, // 排序值
        status: 1, // 显示: 0-隐藏 1-显示
    };

    // 表单校验
    rules: object = {
        name: [{ required: true, message: "请输入标题", trigger: "blur" }],
        cid: [{ required: true, message: "请选择分类", trigger: "change" }],
        synopsis: [{required: true, message: '请输入简介', trigger: 'blur'}],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
    };

    /** E Data **/

    /** S Methods **/
    // 提交表单
    onSubmit(formName: string) {
        const refs = this.$refs[formName] as HTMLFormElement;
        refs.validate((valid: boolean): void => {
            if (!valid) return;

            // 请求发送
            switch (this.mode) {
                case PageMode["ADD"]:
                    return this.handleNoticeAdd();
                case PageMode["EDIT"]:
                    return this.handleNoticeEdit();
            }
        });
    }

    // 添加帮助文章
    handleNoticeAdd() {
        const params = this.form;
        apiNoticeAdd(params)
            .then(() => {
                this.$message.success("添加成功!");
                setTimeout(() => this.$router.go(-1), 500);
            })
            .catch(() => {
                this.$message.error("添加失败!");
            });
    }

    // 编辑帮助文章
    handleNoticeEdit() {
        const params = this.form;
        apiNoticeEdit(params)
            .then(() => {
                // this.$message.success("修改成功!");
                setTimeout(() => this.$router.go(-1), 500);
            })
            .catch(() => {
                this.$message.error("修改失败!");
            });
    }

    // 初始化表单数据: 角色编辑
    initFormDataForNoticeEdit() {
        apiNoticeDetail({
            id: this.form.id,
        })
            .then((res) => {
                Object.keys(res).map((item) => {
                    this.$set(this.form, item, res[item]);
                });
            })
            .catch(() => {
                this.$message.error("数据初始化失败，请刷新重载！");
            });
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query;
        if (query.mode) this.mode = query.mode;

        if (this.mode === PageMode["EDIT"]) {
            this.form.id = query.id * 1;
            this.initFormDataForNoticeEdit();
        }
    }

    /** E Life Cycle **/
}
